'use client';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SeparatorText,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredFieldMessage } from 'piramid-constants';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInRequestBody } from 'bff';
import { useVerifyAccount } from '@/hooks/useVerifyAccount';
import { loginUser } from '@/lib/loginUser';
import { useApiError } from 'shared-components';
import { VerificationCode } from './VerificationCode';
import { Authenticate } from './Authenticate';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
  password: z.string().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export type SignInIDPFn = (data: SignInRequestBody) => Promise<any>;

export interface LoginUserFormProps
  extends Pick<AuthenticateProps, 'inverseAction'> {
  signInIDP: SignInIDPFn;
  onDone: () => void;
  forgotPasswordHref?: string;
  children?: React.ReactNode;
  onVerificationCodeOpen?: (open: boolean) => void;
}

export const LoginUserForm = ({
  onDone,
  forgotPasswordHref,
  children,
  onVerificationCodeOpen,
  inverseAction,
}: LoginUserFormProps) => {
  return (
    <Authenticate
      context='login'
      title='Inicia sesión'
      description={`Crea un reclamo, sigue los estados en tiempo real y\nmucho más.`}
      back={{
        label: 'Volver al login',
      }}
      submit={{
        label: 'Iniciar sesión',
        handle: loginUser,
        forceVerification: false,
      }}
      inverseAction={inverseAction}
      callbacks={{
        onDone,
      }}
    />
  );
};
