import { useCallback, useState } from 'react';
import { credentialsResendAccountVerification } from '@/lib/credentialsResendAccountVerification';
import { toast, useToast } from 'ui';
import { LoginFormData, LoginUserFormProps } from 'shared-components';

const msToMinutes = (ms: number) => Math.floor(ms / 60000);

export const useVerifyAccount = () => {
  const [otpCredentials, setOtpCredentials] = useState<LoginFormData | null>(
    null,
  );

  const { toast } = useToast();

  const handleResendVerificationCode = useCallback(
    (credentials: LoginFormData) =>
      credentialsResendAccountVerification({
        body: credentials,
      }),
    [],
  );

  const handleUserNotVerified = useCallback(
    async ({
      credentials,
      errorMetadata,
      onVerificationCodeOpen,
    }: Parameters<
      NonNullable<LoginUserFormProps['callbacks']>['user_not_verified']
    >[0] & {
      onVerificationCodeOpen?: (open: boolean) => void;
    }) => {
      if (!errorMetadata.can_resend) {
        toast({
          title: 'Cuenta sin verificar',
          description: `Debido a intentos fallidos de verificación, debes esperar ${msToMinutes(
            errorMetadata.wait_time_ms,
          )} minutos antes de poder reenviar el código de verificación`,
        });

        return false;
      }

      await handleResendVerificationCode(credentials);

      setOtpCredentials(credentials);
      onVerificationCodeOpen?.(true);
      return true;
    },
    [],
  );

  const clearOtpCredentials = useCallback(() => {
    setOtpCredentials(null);
  }, []);

  return {
    setOtpCredentials,
    otpCredentials,
    handleResendVerificationCode,
    handleUserNotVerified,
    clearOtpCredentials,
  };
};
