'use client';
import { SignInWithGoogle } from '@/components/SignInWithGoogle';
import { useVerifyAccount } from '@/hooks/useVerifyAccount';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import {
  AuthenticateForm,
  AuthenticateFormData,
  AuthenticateFormProps,
  BackToButton,
  PiramidLogo,
  AuthenticateProps,
  AuthenticateContainer,
  AuthenticateLogo,
  AuthenticateTitle,
  AuthenticateDescription,
  AuthenticationIDPContainer,
  AuthenticationMoreOptions,
  AuthenticateTOS,
} from 'shared-components';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  useToast,
} from 'ui';
import { signInIDP } from '../lib/signInIDP';
import { signUpIDP } from '../lib/signUpIDP';
import { AuthLink } from './AuthLink';
import { VerificationCode } from './VerificationCode';

export const Authenticate = ({
  title,
  description,
  inverseAction,
  context,
  back,
  submit,
  callbacks,
}: AuthenticateProps) => {
  const [showLocalStrategy, setShowLocalStrategy] = useState(false);
  const router = useRouter();

  const {
    otpCredentials,
    handleResendVerificationCode,
    handleUserNotVerified,
    clearOtpCredentials,
    setOtpCredentials,
  } = useVerifyAccount();

  const { toast } = useToast();

  const handleBack = useCallback(() => {
    setShowLocalStrategy(false);
    clearOtpCredentials();
  }, []);

  const handleSubmit = useCallback(
    async (data: AuthenticateFormData) => {
      await submit.handle(data);

      if (submit.forceVerification) return setOtpCredentials(data);

      callbacks.onDone();
    },
    [submit],
  );

  if (otpCredentials)
    return (
      <AuthenticateContainer>
        <VerificationCode
          email={otpCredentials.email}
          onDone={callbacks.onDone}
          onResendVerificationCode={() =>
            handleResendVerificationCode(otpCredentials!)
          }
        />

        <div className='mt-9'>
          <BackToButton handleBack={handleBack}>{back.label}</BackToButton>
        </div>
      </AuthenticateContainer>
    );

  return (
    <AuthenticateContainer>
      <AuthenticateLogo />
      <AuthenticateTitle>{title}</AuthenticateTitle>

      <AuthenticateDescription>{description}</AuthenticateDescription>

      {showLocalStrategy ? (
        <AuthenticateForm
          submit={{
            label: submit.label,
            handle: handleSubmit,
          }}
          context={context}
          back={{
            handle: handleBack,
            label: back.label,
          }}
          callbacks={{
            user_not_verified: handleUserNotVerified,
          }}
        />
      ) : (
        <>
          <AuthenticationIDPContainer>
            <SignInWithGoogle
              signIn={context === 'login' ? signInIDP : signUpIDP}
              onDone={callbacks.onDone}
              variant='white'
              small={true}
              actionLabel='Continuar'
            />
            <AuthenticationMoreOptions
              inverseAction={inverseAction}
              handleContinueWithEmail={() => setShowLocalStrategy(true)}
            />
          </AuthenticationIDPContainer>
          <AuthenticateTOS />
        </>
      )}
    </AuthenticateContainer>
  );
};
