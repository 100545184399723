'use client';
import { useApiError } from '@/hooks/useApiError';
import { registerUser } from '@/lib/registerUser';
import { zodResolver } from '@hookform/resolvers/zod';
import { RegisterUserRequestBody, registerUserRequestBody } from 'bff';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SeparatorText,
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
  useToast,
  cn,
} from 'ui';
import { FormTitle } from './FormLayout';
import {
  AuthenticateFormData,
  BackToButton,
  PiramidLogo,
  AuthenticateProps,
} from 'shared-components';
import { SignInWithGoogle } from './SignInWithGoogle';
import { signUpIDP } from '../lib/signUpIDP';
import { CheckCircle, Mail } from 'lucide-react';
import { loginUser } from '@/lib/loginUser';
import { VerificationCode } from './VerificationCode';
import { useVerifyAccount } from '@/hooks/useVerifyAccount';
import { LoginUserForm } from './LoginUserForm';
import { Authenticate } from './Authenticate';

type FormData = RegisterUserRequestBody;

export const RegisterUserForm = ({
  onDone,
  withHeader = true,
  verifyEmailOpts,
  inverseAction,
}: {
  onDone: () => void;
  withHeader?: boolean;
  verifyEmailOpts?: {
    className?: string;
  };
} & Pick<AuthenticateProps, 'inverseAction'>) => {
  return (
    <Authenticate
      context='register'
      title='Regístrate'
      description='Crea un reclamo, sigue los estados en tiempo real y mucho más.'
      back={{
        label: 'Volver al registro',
      }}
      submit={{
        label: 'Registrarme',
        handle: registerUser,
        forceVerification: true,
      }}
      callbacks={{
        onDone,
      }}
      inverseAction={inverseAction}
    />
  );
};
