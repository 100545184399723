import { httpClient } from '@/http/client';
import {
  SignInRequestBody,
  LoginUserResponseBody,
  SignUpRequestBody,
} from 'bff';
import { UserType } from 'database';

export const signUpIDP = async (
  data: SignUpRequestBody,
): Promise<LoginUserResponseBody> =>
  await httpClient
    .headers({
      'x-user-type': 'third' satisfies UserType,
    })
    .post(data, '/v1/auth/identity/sign-up')
    .json();
