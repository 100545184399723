import { httpClient } from '@/http/client';
import { RegisterUserRequestBody } from 'bff';

export const registerUser = async (
  data: RegisterUserRequestBody,
): Promise<any> => {
  return await httpClient
    .headers({
      'x-user-type': 'third',
    })
    .post(data, '/v1/auth/register')
    .json();
};
