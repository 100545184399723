'use client';
import { useCallback, useState } from 'react';
import {
  cn,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  SeparatorText,
} from 'ui';
import { LoginLink } from './LoginLink';
import { RegisterLink } from './RegisterLink';
import { RegisterUserForm } from './RegisterUserForm';
import { useRouter } from 'next/navigation';
import { FetchEntrypointResponseBody } from 'bff';
import { useTranslations } from 'i11n';
import { inter_classname, PiramidLogo } from 'shared-components';
import { signInIDP } from '../lib/signInIDP';
import { loginUser } from '@/lib/loginUser';
import { SignInWithGoogle } from './SignInWithGoogle';
import { signUpIDP } from '../lib/signUpIDP';
import { LoginUserForm } from './LoginUserForm';

export const authenticationInverseActionContent = {
  login: {
    destination: 'Inicia sesión',
    question: '¿Ya tienes una cuenta?',
  },
  register: {
    destination: 'Regístrate',
    question: '¿No tienes una cuenta?',
  },
};

export const GatedContentRegister = ({
  entrypoint,
  resource = 'claims',
}: {
  entrypoint: FetchEntrypointResponseBody;
  resource?: 'claims' | 'complaints';
}) => {
  const [action, setAction] = useState<'register' | 'login'>('register');

  const router = useRouter();

  const onAuthenticated = useCallback(() => {
    router.push(`/${resource}/create/${entrypoint.endpoint}`);
  }, []);

  const t = useTranslations();

  const [verificationCodeOpen, setVerificationCodeOpen] = useState(false);

  return (
    <Dialog open>
      <DialogContent
        hideClose
        className={cn(
          inter_classname,
          'space-y-4 flex flex-col items-center justify-start py-12',
        )}
      >
        <div className='w-full flex items-center justify-center'>
          {action === 'register' ? (
            <>
              <RegisterUserForm
                verifyEmailOpts={{
                  className: 'border-none shadow-none',
                }}
                onDone={onAuthenticated}
                inverseAction={{
                  type: 'callback',
                  callback: () => setAction('login'),
                  ...authenticationInverseActionContent.login,
                }}
                withHeader={false}
              />
            </>
          ) : (
            <>
              <LoginUserForm
                signInIDP={(data) => signInIDP(data).then(onAuthenticated)}
                loginUser={(data) => loginUser(data).then(onAuthenticated)}
                onDone={onAuthenticated}
                onVerificationCodeOpen={setVerificationCodeOpen}
                inverseAction={{
                  type: 'callback',
                  callback: () => setAction('register'),
                  ...authenticationInverseActionContent.register,
                }}
              >
                <RegisterLink asButton onClick={() => setAction('register')} />
              </LoginUserForm>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
